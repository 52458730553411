import React from 'react'
import { selectTranslations } from '../../../reduxx/languageSlice';
import { useSelector } from 'react-redux';

import '../matchInfo/matchinfo.css'
const WeatherCondition = ({matchInfoData}) => {
    const translations = useSelector(selectTranslations)

    return (
        <div className='section-weather'>
            <div className="head-wrapper flex">
                <p className='batting-career-name'>{translations['WeatherCondition']} </p>
            </div>
            <div className='section-cloud'>
                <div className='sec-green'>
                    <p className='small-para-2'>{matchInfoData?.sub_title}, <span>{matchInfoData?.venue?.name}</span>, {matchInfoData?.venue?.city}</p>
                </div>
                <div className='section-temp'>
                    <div className='cloud'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="44" viewBox="0 0 40 44" fill="none">
                            <path d="M19.75 44L24.6 38.5L20.6 36.5L26.25 30H30.25L25.4 35.5L29.4 37.5L23.75 44H19.75ZM7.75 44L12.6 38.5L8.6 36.5L14.25 30H18.25L13.4 35.5L17.4 37.5L11.75 44H7.75ZM10.5 27C7.60453 27 5.13067 25.9747 3.0784 23.924C1.02613 21.8733 0 19.4014 0 16.5082C0 13.8694 0.941667 11.5167 2.825 9.45C4.70833 7.38333 7.05 6.25 9.85 6.05C10.9167 4.18333 12.325 2.70833 14.075 1.625C15.825 0.541667 17.8071 0 20.0212 0C23.0404 0 25.575 0.958333 27.625 2.875C29.675 4.79167 30.9333 7.16667 31.4 10C34.0333 10.1333 36.125 11.025 37.675 12.675C39.225 14.325 40 16.2604 40 18.4811C40 20.827 39.1736 22.8333 37.5209 24.5C35.868 26.1667 33.8611 27 31.5 27H10.5ZM10.5 24H31.5C33.04 24 34.3417 23.4583 35.405 22.375C36.4683 21.2917 37 19.9917 37 18.475C37 16.9583 36.4683 15.6667 35.405 14.6C34.3417 13.5333 33.04 13 31.5 13H28.5V11.5C28.5 9.13333 27.675 7.125 26.025 5.475C24.375 3.825 22.3702 3 20.0106 3C18.2946 3 16.7304 3.45833 15.3182 4.375C13.9061 5.29167 12.8667 6.53333 12.2 8.1L11.8 9H10.4C8.33333 9.06667 6.58333 9.82322 5.15 11.2697C3.71667 12.7161 3 14.4571 3 16.4928C3 18.5643 3.73215 20.3333 5.19645 21.8C6.66072 23.2667 8.42857 24 10.5 24Z" fill="white" />
                        </svg>
                        <h5 className='regular-para'>Cloudy</h5>
                    </div>
                    <div className='temp'>
                        <h5 className='regular-para'>{matchInfoData?.weather}</h5>
                    </div>
                </div>
                <div className='section-humi'>
                    <div className='humidity'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                            <path d="M10 18.4173C8.15279 18.4173 6.57987 17.7784 5.28126 16.5007C3.98265 15.2229 3.33334 13.6673 3.33334 11.834C3.33334 10.959 3.50348 10.1222 3.84376 9.32357C4.18404 8.52496 4.66668 7.8201 5.29168 7.20898L10 2.58398L14.7083 7.20898C15.3333 7.8201 15.816 8.52496 16.1563 9.32357C16.4965 10.1222 16.6667 10.959 16.6667 11.834C16.6667 13.6673 16.0174 15.2229 14.7188 16.5007C13.4201 17.7784 11.8472 18.4173 10 18.4173Z" fill="#0080DC" />
                        </svg>
                        <p className='humidity-para'>90% Humidity</p>
                    </div>
                    <div className='section-rain'>
                        <div className='lighting'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M7.04167 16.2492C6.83333 16.3603 6.62153 16.3776 6.40625 16.3012C6.19097 16.2249 6.02778 16.0825 5.91667 15.8742L0.916667 5.87416C0.805556 5.66582 0.788195 5.45402 0.864584 5.23874C0.940973 5.02346 1.08333 4.86027 1.29167 4.74916C1.5 4.63804 1.71181 4.62068 1.92708 4.69707C2.14236 4.77346 2.30556 4.91582 2.41667 5.12416L7.41667 15.1242C7.52778 15.3325 7.54514 15.5443 7.46875 15.7596C7.39236 15.9749 7.25 16.138 7.04167 16.2492ZM10.9375 16.2492C10.7292 16.3603 10.5174 16.3776 10.3021 16.3012C10.0868 16.2249 9.92361 16.0825 9.8125 15.8742L4.8125 5.87416C4.70139 5.66582 4.68403 5.45402 4.76042 5.23874C4.83681 5.02346 4.97917 4.86027 5.1875 4.74916C5.39583 4.63804 5.60417 4.62068 5.8125 4.69707C6.02083 4.77346 6.18056 4.91582 6.29167 5.12416L11.3125 15.1242C11.4236 15.3325 11.441 15.5443 11.3646 15.7596C11.2882 15.9749 11.1458 16.138 10.9375 16.2492ZM18.7083 16.2492C18.5 16.3603 18.2882 16.3776 18.0729 16.3012C17.8576 16.2249 17.6944 16.0825 17.5833 15.8742L12.5833 5.87416C12.4722 5.66582 12.4549 5.45402 12.5312 5.23874C12.6076 5.02346 12.75 4.86027 12.9583 4.74916C13.1667 4.63804 13.3785 4.62068 13.5938 4.69707C13.809 4.77346 13.9722 4.91582 14.0833 5.12416L19.0833 15.1242C19.1944 15.3325 19.2118 15.5443 19.1354 15.7596C19.059 15.9749 18.9167 16.138 18.7083 16.2492ZM14.8333 16.2283C14.625 16.3394 14.4132 16.3603 14.1979 16.2908C13.9826 16.2214 13.8194 16.0825 13.7083 15.8742L8.70833 5.87416C8.59722 5.66582 8.57986 5.45402 8.65625 5.23874C8.73264 5.02346 8.875 4.86027 9.08333 4.74916C9.29167 4.63804 9.5 4.62068 9.70833 4.69707C9.91667 4.77346 10.0764 4.91582 10.1875 5.12416L15.2083 15.1242C15.3194 15.3325 15.3368 15.5408 15.2604 15.7492C15.184 15.9575 15.0417 16.1172 14.8333 16.2283Z" fill="#0080DC" />
                            </svg>
                            <p className='humidity-para'>40% Chance (Rain)</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeatherCondition